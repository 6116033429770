<template>
  <div>
    <div v-if="settlementForm.outType !== 2">
      <div class="area-title">
        <p class="title">
          <span>{{ utils.statusFormat(String(settlementForm.settlementType), 'StatementType') }}</span>
        </p>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="订单名称：" :value="utils.dataString(settlementForm.orderName)" />
          <van-cell title="订单编号：" :value="utils.isEffectiveCommon(settlementForm.orderNo)" />
          <van-cell title="关联合同：" :value="utils.isEffectiveCommon(settlementForm.contractName)" />
          <van-cell title="合同编号：" :value="utils.isEffectiveCommon(settlementForm.contractNumber)" />
          <van-cell title="付款方类型：" :value="utils.statusFormat(String(settlementForm.payerType), 'EnterpriseOrgType')" />
          <van-cell title="收款方类型：" :value="utils.statusFormat(String(settlementForm.collectionMethodType), 'EnterpriseOrgType')" />
          <van-cell title="付款方：" :value="utils.isEffectiveCommon(settlementForm.payerOrgName)" />
          <van-cell title="收款方：" :value="utils.isEffectiveCommon(settlementForm.beneficiaryOrgName)" />
          <van-cell title="付款方开户行：" :value="utils.isEffectiveCommon(settlementForm.payerAccountBank)" />
          <van-cell title="收款方开户行：" :value="utils.isEffectiveCommon(settlementForm.beneficiaryAccountBank)" />
          <van-cell title="付款账号：" :value="utils.isEffectiveCommon(settlementForm.paymentAccount)" />
          <van-cell title="收款账号：" :value="utils.isEffectiveCommon(settlementForm.accountsReceivable)" />
          <van-cell title="提货申请编号：" :value="utils.isEffectiveCommon(settlementForm.applyNo)" />
          <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(settlementForm.warehouseName)" />
          <van-cell title="提货类型：" :value="utils.statusFormat(String(settlementForm.outType),'StorageOutType')" />
          <van-cell title="代理费类型：" :value="utils.statusFormat(String(settlementForm.platformServiceType),'ProjectPlatformServiceType')" />
        </van-cell-group>
      </div>
      <div class="area-title">
        <p class="title">
          货物总价
        </p>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="合同号：" :value="utils.dataString(settlementForm.statementCargoPriceInfoVo.pi)" />
          <van-cell title="柜号：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.cabinetNo)" />
          <van-cell title="品名：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.cargoName)" />
          <van-cell title="国家：" :value="utils.statusFormat(settlementForm.statementCargoPriceInfoVo.country, 'Country')" />
          <van-cell title="件数：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.actualOutPieceNumber)" />
          <van-cell title="重量（KG）：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.actualOutWeight)" />
          <van-cell title="不含税销售价格：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.priceExcludingTax)" />
          <van-cell title="销项税额：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.outputTax)" />
          <van-cell title="贷款总合计：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.totalLoans)" />
        </van-cell-group>
      </div>
      <div v-if="!_.isEmpty(settlementForm.statementRePayInfoVoList)">
        <div class="area-title">
          <p class="title">
            本单资金收付明细
          </p>
        </div>
        <div v-for="item in settlementForm.statementRePayInfoVoList" :key="item.id" class="van-common-detail">
          <van-cell-group>
            <van-cell title="费用类型：" :value="item.feeProject" />
            <van-cell title="合同号：" :value="utils.dataString(item.pi)" />
            <van-cell title="货值原币：" :value="utils.isEffectiveCommon(item.money)" />
            <van-cell title="汇率：" :value="utils.isEffectiveCommon(item.rate)" />
            <van-cell title="货款：" :value="utils.isEffectiveCommon(item.cargoMoney)" />
            <van-cell title="付汇费用：" :value="utils.isEffectiveCommon(item.bankCharges)" />
            <van-cell title="日期：" :value="utils.dateFormat(item.costTime)" />
            <van-cell title="总金额：" :value="utils.isEffectiveCommon(item.totalMoney)" />
          </van-cell-group>
        </div>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="客户支付货款：" :value="utils.dataString(settlementForm.statementCargoPriceInfoVo.customerPayMoney)" />
          <van-cell title="总金额：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.totalMoney)" />
          <van-cell title="垫付金额：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.advanceMoney)" />
          <van-cell title="尾款垫付日期：" :value="utils.dateFormat(settlementForm.statementCargoPriceInfoVo.balancePaymentTime)" />
          <van-cell title="回款时间：" :value="utils.dateFormat(settlementForm.statementCargoPriceInfoVo.collectionTime)" />
          <van-cell title="利息天数：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.interestDays)" />
          <van-cell title="日利率：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.dailyInterestRate)" />
          <van-cell title="货款利息：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.loanInterest)" />
        </van-cell-group>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="关税：" :value="utils.dataString(settlementForm.statementCargoPriceInfoVo.tariff)" />
          <van-cell title="增值税：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.valueAddedTax)" />
          <van-cell title="支付时间：" :value="utils.dateFormat(settlementForm.statementCargoPriceInfoVo.payTime)" />
          <van-cell title="回款时间：" :value="utils.dateFormat(settlementForm.statementCargoPriceInfoVo.collectionTimeLessSeven)" />
          <van-cell title="利息天数：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.interestDaysLessSeven)" />
          <van-cell title="日利率：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.dailyInterestRate)" />
          <van-cell title="税金利息：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.taxInterest)" />
        </van-cell-group>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="实际销项税额：" :value="utils.dataString(settlementForm.statementCargoPriceInfoVo.actualOutputTax)" />
          <van-cell title="实际销项-实际进项：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.outputTaxDif)" />
          <van-cell title="汇款手续费+汇率变动引起的进项损益：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.inputProfitAndLoss)" />
          <van-cell title="保险费：" :value="utils.isEffectiveCommon(settlementForm.statementCargoPriceInfoVo.insurancePremium)" />
        </van-cell-group>
      </div>
      <div class="van-common-detail">
        <van-cell>
          <table class="inner-goods-table payment-table" border="1">
            <tr>
              <th colspan="4" class="header-th">
                <span>货款结算明细</span>
              </th>
            </tr>

            <tbody>
              <th>序号</th><th>项目</th><th>金额（元）</th><th>备注</th>
            </tbody>
            <tr v-for="(it,idx) in settlementForm.statementCalculationInfoVoList" :key="idx">
              <td>{{ idx+1 }}</td><td>{{ utils.isEffectiveCommon(it.feeProject) }}</td><td>{{ utils.moneyFormat(it.feeMoney) }}</td><td>{{ utils.isEffectiveCommon(it.remarks) }}</td>
            </tr>
          </table>
        </van-cell>
      </div>
      <div class="van-common-detail">
        <van-cell title="应支付总金额">
          {{ utils.moneyFormat(allTotal()) }}
        </van-cell>
      </div>
    </div>
    <div v-else>
      <div class="area-title">
        <p class="title">
          <span>提货结算单</span>
        </p>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="订单名称：" :value="utils.dataString(settlementForm.orderName)" />
          <van-cell title="订单编号：" :value="utils.isEffectiveCommon(settlementForm.orderNo)" />
          <van-cell title="关联合同：" :value="utils.isEffectiveCommon(settlementForm.contractName)" />
          <van-cell title="合同编号：" :value="utils.isEffectiveCommon(settlementForm.contractNumber)" />
          <van-cell title="付款方类型：" :value="utils.statusFormat(String(settlementForm.payerType), 'EnterpriseOrgType')" />
          <van-cell title="收款方类型：" :value="utils.statusFormat(String(settlementForm.collectionMethodType), 'EnterpriseOrgType')" />
          <van-cell title="付款方：" :value="utils.isEffectiveCommon(settlementForm.payerOrgName)" />
          <van-cell title="收款方：" :value="utils.isEffectiveCommon(settlementForm.beneficiaryOrgName)" />
          <van-cell title="付款方开户行：" :value="utils.isEffectiveCommon(settlementForm.payerAccountBank)" />
          <van-cell title="收款方开户行：" :value="utils.isEffectiveCommon(settlementForm.beneficiaryAccountBank)" />
          <van-cell title="付款账号：" :value="utils.isEffectiveCommon(settlementForm.paymentAccount)" />
          <van-cell title="收款账号：" :value="utils.isEffectiveCommon(settlementForm.accountsReceivable)" />
          <van-cell title="提货申请编号：" :value="utils.isEffectiveCommon(settlementForm.applyNo)" />
          <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(settlementForm.warehouseName)" />
          <van-cell title="提货类型：" :value="utils.statusFormat(String(settlementForm.outType),'StorageOutType')" />
          <van-cell title="代理费类型：" :value="utils.statusFormat(String(settlementForm.platformServiceType),'ProjectPlatformServiceType')" />
        </van-cell-group>
      </div>
      <div class="area-title">
        <p class="title">
          货物信息
        </p>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="柜号：" :value="utils.dataString(settlementForm.logisticsPickUpInfoVo.cabinetNo)" />
          <van-cell title="国家：" :value="utils.statusFormat(settlementForm.logisticsPickUpInfoVo.country, 'Country')" />
          <van-cell title="原产地：" :value="utils.isEffectiveCommon(settlementForm.logisticsPickUpInfoVo.countryOfOrigin)" />
          <van-cell title="HS编码：" :value="utils.isEffectiveCommon(settlementForm.logisticsPickUpInfoVo.hsCode)" />
          <van-cell title="货物名称：" :value="utils.isEffectiveCommon(settlementForm.logisticsPickUpInfoVo.cargoName)" />
          <van-cell title="账面件数：" :value="utils.isEffectiveCommon(settlementForm.logisticsPickUpInfoVo.pieceNumber)" />
          <van-cell title="账面重量（KGS）：" :value="utils.isEffectiveCommon(settlementForm.logisticsPickUpInfoVo.actualWeight)" />
        </van-cell-group>
      </div>
      <div v-if="!_.isEmpty(settlementForm.storageOutGoodsPackInfoVoList)">
        <div class="area-title">
          <p class="title">
            申请提货明细
          </p>
        </div>
        <div v-for="item in settlementForm.storageOutGoodsPackInfoVoList" :key="item.id" class="van-common-detail">
          <van-cell-group>
            <van-cell title="商品编号：" :value="utils.isEffectiveCommon(item.cargoNo)" />
            <van-cell title="品名：" :value="utils.isEffectiveCommon(item.productName)" />
            <van-cell title="单价(原币)：" :value="utils.isEffectiveCommon(item.unitPrice)" />
            <van-cell title="认定单价（元/件）：" :value="utils.isEffectiveCommon(item.packageUnitPrice)" />
            <van-cell title="账面件数：" :value="utils.isEffectiveCommon(item.pieceNumber)" />
            <van-cell title="申请提货件数：" :value="utils.isEffectiveCommon(item.applyNumber)" />
            <van-cell title="价格（元）：" :value="utils.isEffectiveCommon(item.money)" />
          </van-cell-group>
        </div>
      </div>
      <div class="van-common-detail ">
        <van-cell-group>
          <van-cell title="货款合计（元）：" :value="utils.isEffectiveCommon(settlementForm.statementCalculationInfoVoList[0].feeMoney)" />
        </van-cell-group>
      </div>
      <div class="van-common-detail">
        <van-cell>
          <table class="inner-goods-table payment-table" border="1">
            <tr>
              <th colspan="4" class="header-th">
                <span>结算明细</span>
              </th>
            </tr>

            <tbody>
              <th>序号</th><th>费用项目</th><th>金额（元）</th><th>备注</th>
            </tbody>
            <tr v-for="(it,idx) in settlementForm.statementCalculationInfoVoList" :key="idx">
              <td>{{ idx+1 }}</td><td>{{ utils.isEffectiveCommon(it.feeProject) }}</td><td>{{ utils.moneyFormat(it.feeMoney) }}</td><td>{{ utils.isEffectiveCommon(it.remarks) }}</td>
            </tr>
          </table>
        </van-cell>
      </div>
      <div class="van-common-detail">
        <van-cell title="应支付总金额">
          {{ utils.moneyFormat(allTotal()) }}
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    settlementDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    // 业务ID
    businessId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      settlementForm: {
        // 关联合同
        contractNumber: '',
        // 付款方类型
        payerType: '',
        // 付款方企业ID
        payerEnterpriseId: '',
        // 收款方类型
        collectionMethodType: '',
        // 收款方企业ID
        beneficiaryEnterpriseId: '',
        // 付款方开户行
        payerAccountBank: '',
        // 收款方开户行
        beneficiaryAccountBank: '',
        // 付款账号
        paymentAccount: '',
        // 收款账号
        accountsReceivable: '',
        // 费用信息
        statementFeeInfoVoList: [],
        // 货款结算明细
        statementCalculationInfoVoList: [],
        // 结算单货物总价信息
        statementCargoPriceInfoVo: {}
      },
      loading: false,
      finished: false,
      appDownList: [],
      amountPaid: 0
    }
  },
  created () {
    this.getStatementDetail()
  },
  methods: {
    getStatementDetail () {
      this.api.collection.statementDetail({ businessid: this.businessId }).then(result => {
        this.settlementForm = this._.cloneDeep(result.data.value)
      }).finally(() => {
      })
    },
    allTotal () {
      let total = 0
      this.settlementForm.statementCalculationInfoVoList.forEach(item => {
        if (item.feeMoney) {
          total += Number(item.feeMoney)
        }
      })
      this.settlementForm.statementMoney = total
      return total
    }
  }
}
</script>

<style lang="less">
</style>

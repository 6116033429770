var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.settlementForm.outType !== 2
      ? _c("div", [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.utils.statusFormat(
                      String(_vm.settlementForm.settlementType),
                      "StatementType"
                    )
                  )
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "订单名称：",
                      value: _vm.utils.dataString(_vm.settlementForm.orderName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "订单编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.orderNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "关联合同：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.contractName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "合同编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.contractNumber
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.payerType),
                        "EnterpriseOrgType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.collectionMethodType),
                        "EnterpriseOrgType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.payerOrgName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.beneficiaryOrgName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方开户行：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.payerAccountBank
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方开户行：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.beneficiaryAccountBank
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款账号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.paymentAccount
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款账号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.accountsReceivable
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "提货申请编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.applyNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "仓库名称：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.warehouseName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "提货类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.outType),
                        "StorageOutType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "代理费类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.platformServiceType),
                        "ProjectPlatformServiceType"
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "合同号：",
                      value: _vm.utils.dataString(
                        _vm.settlementForm.statementCargoPriceInfoVo.pi
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "柜号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.cabinetNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "品名：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.cargoName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "国家：",
                      value: _vm.utils.statusFormat(
                        _vm.settlementForm.statementCargoPriceInfoVo.country,
                        "Country"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "件数：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .actualOutPieceNumber
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "重量（KG）：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .actualOutWeight
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "不含税销售价格：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .priceExcludingTax
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "销项税额：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.outputTax
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "贷款总合计：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.totalLoans
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm._.isEmpty(_vm.settlementForm.statementRePayInfoVoList)
            ? _c(
                "div",
                [
                  _vm._m(1),
                  _vm._l(_vm.settlementForm.statementRePayInfoVoList, function(
                    item
                  ) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "van-common-detail" },
                      [
                        _c(
                          "van-cell-group",
                          [
                            _c("van-cell", {
                              attrs: {
                                title: "费用类型：",
                                value: item.feeProject
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "合同号：",
                                value: _vm.utils.dataString(item.pi)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "货值原币：",
                                value: _vm.utils.isEffectiveCommon(item.money)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "汇率：",
                                value: _vm.utils.isEffectiveCommon(item.rate)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "货款：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.cargoMoney
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "付汇费用：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.bankCharges
                                )
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "日期：",
                                value: _vm.utils.dateFormat(item.costTime)
                              }
                            }),
                            _c("van-cell", {
                              attrs: {
                                title: "总金额：",
                                value: _vm.utils.isEffectiveCommon(
                                  item.totalMoney
                                )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "客户支付货款：",
                      value: _vm.utils.dataString(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .customerPayMoney
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "总金额：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.totalMoney
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "垫付金额：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .advanceMoney
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "尾款垫付日期：",
                      value: _vm.utils.dateFormat(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .balancePaymentTime
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "回款时间：",
                      value: _vm.utils.dateFormat(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .collectionTime
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "利息天数：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .interestDays
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "日利率：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .dailyInterestRate
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "货款利息：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .loanInterest
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "关税：",
                      value: _vm.utils.dataString(
                        _vm.settlementForm.statementCargoPriceInfoVo.tariff
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "增值税：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .valueAddedTax
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "支付时间：",
                      value: _vm.utils.dateFormat(
                        _vm.settlementForm.statementCargoPriceInfoVo.payTime
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "回款时间：",
                      value: _vm.utils.dateFormat(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .collectionTimeLessSeven
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "利息天数：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .interestDaysLessSeven
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "日利率：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .dailyInterestRate
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "税金利息：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo.taxInterest
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "实际销项税额：",
                      value: _vm.utils.dataString(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .actualOutputTax
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "实际销项-实际进项：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .outputTaxDif
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "汇款手续费+汇率变动引起的进项损益：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .inputProfitAndLoss
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "保险费：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCargoPriceInfoVo
                          .insurancePremium
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", [
                _c(
                  "table",
                  {
                    staticClass: "inner-goods-table payment-table",
                    attrs: { border: "1" }
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "header-th", attrs: { colspan: "4" } },
                        [_c("span", [_vm._v("货款结算明细")])]
                      )
                    ]),
                    _c("tbody", [
                      _c("th", [_vm._v("序号")]),
                      _c("th", [_vm._v("项目")]),
                      _c("th", [_vm._v("金额（元）")]),
                      _c("th", [_vm._v("备注")])
                    ]),
                    _vm._l(
                      _vm.settlementForm.statementCalculationInfoVoList,
                      function(it, idx) {
                        return _c("tr", { key: idx }, [
                          _c("td", [_vm._v(_vm._s(idx + 1))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.isEffectiveCommon(it.feeProject))
                            )
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.utils.moneyFormat(it.feeMoney)))
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.isEffectiveCommon(it.remarks))
                            )
                          ])
                        ])
                      }
                    )
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", { attrs: { title: "应支付总金额" } }, [
                _vm._v(
                  " " + _vm._s(_vm.utils.moneyFormat(_vm.allTotal())) + " "
                )
              ])
            ],
            1
          )
        ])
      : _c("div", [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "订单名称：",
                      value: _vm.utils.dataString(_vm.settlementForm.orderName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "订单编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.orderNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "关联合同：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.contractName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "合同编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.contractNumber
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.payerType),
                        "EnterpriseOrgType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.collectionMethodType),
                        "EnterpriseOrgType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.payerOrgName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.beneficiaryOrgName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款方开户行：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.payerAccountBank
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款方开户行：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.beneficiaryAccountBank
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "付款账号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.paymentAccount
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "收款账号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.accountsReceivable
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "提货申请编号：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.applyNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "仓库名称：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.warehouseName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "提货类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.outType),
                        "StorageOutType"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "代理费类型：",
                      value: _vm.utils.statusFormat(
                        String(_vm.settlementForm.platformServiceType),
                        "ProjectPlatformServiceType"
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._m(3),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "柜号：",
                      value: _vm.utils.dataString(
                        _vm.settlementForm.logisticsPickUpInfoVo.cabinetNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "国家：",
                      value: _vm.utils.statusFormat(
                        _vm.settlementForm.logisticsPickUpInfoVo.country,
                        "Country"
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "原产地：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.logisticsPickUpInfoVo.countryOfOrigin
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "HS编码：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.logisticsPickUpInfoVo.hsCode
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "货物名称：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.logisticsPickUpInfoVo.cargoName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "账面件数：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.logisticsPickUpInfoVo.pieceNumber
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "账面重量（KGS）：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.logisticsPickUpInfoVo.actualWeight
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm._.isEmpty(_vm.settlementForm.storageOutGoodsPackInfoVoList)
            ? _c(
                "div",
                [
                  _vm._m(4),
                  _vm._l(
                    _vm.settlementForm.storageOutGoodsPackInfoVoList,
                    function(item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "van-common-detail" },
                        [
                          _c(
                            "van-cell-group",
                            [
                              _c("van-cell", {
                                attrs: {
                                  title: "商品编号：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.cargoNo
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "品名：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.productName
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "单价(原币)：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.unitPrice
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "认定单价（元/件）：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.packageUnitPrice
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "账面件数：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.pieceNumber
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "申请提货件数：",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.applyNumber
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "价格（元）：",
                                  value: _vm.utils.isEffectiveCommon(item.money)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "van-common-detail " },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: {
                      title: "货款合计（元）：",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.settlementForm.statementCalculationInfoVoList[0]
                          .feeMoney
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", [
                _c(
                  "table",
                  {
                    staticClass: "inner-goods-table payment-table",
                    attrs: { border: "1" }
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "header-th", attrs: { colspan: "4" } },
                        [_c("span", [_vm._v("结算明细")])]
                      )
                    ]),
                    _c("tbody", [
                      _c("th", [_vm._v("序号")]),
                      _c("th", [_vm._v("费用项目")]),
                      _c("th", [_vm._v("金额（元）")]),
                      _c("th", [_vm._v("备注")])
                    ]),
                    _vm._l(
                      _vm.settlementForm.statementCalculationInfoVoList,
                      function(it, idx) {
                        return _c("tr", { key: idx }, [
                          _c("td", [_vm._v(_vm._s(idx + 1))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.isEffectiveCommon(it.feeProject))
                            )
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.utils.moneyFormat(it.feeMoney)))
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.isEffectiveCommon(it.remarks))
                            )
                          ])
                        ])
                      }
                    )
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "van-common-detail" },
            [
              _c("van-cell", { attrs: { title: "应支付总金额" } }, [
                _vm._v(
                  " " + _vm._s(_vm.utils.moneyFormat(_vm.allTotal())) + " "
                )
              ])
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物总价 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 本单资金收付明细 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_c("span", [_vm._v("提货结算单")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 申请提货明细 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }